var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tippy',{attrs:{"arrow":"","arrowType":"round","interactive":"","theme":"light action-modal","trigger":"click","maxWidth":"500","size":"large","allowHTML":"","placement":"bottom-end","to":_vm.to}},[_c('div',{staticClass:"action-modal"},[_c('div',{staticClass:"row"},[_c('dropdown',{staticClass:"field",attrs:{"placeholder":"DD/MM/HH","colors":{
					text: 'black',
					bg: '#DFDFDF',
					border: '#DFDFDF',
				}},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('date-icon')]},proxy:true}])}),_c('dropdown',{staticClass:"field",attrs:{"placeholder":"MM:HH","colors":{
					text: 'black',
					bg: '#DFDFDF',
					border: '#DFDFDF',
				}},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('clock-icon')]},proxy:true}])})],1),_c('div',{staticClass:"row"},[_c('dropdown',{staticClass:"field",attrs:{"placeholder":"Schedule","colors":{
					bg: '#0D69D5',
				},"options":_vm.options.schedule,"menu":"","config":{ label: 'title', trackBy: 'value' }},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('calendar-icon')]},proxy:true}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }